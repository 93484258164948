import { OMSProvidersType } from "@/sample/queries";
import { useMemo } from "react";
import { queryVars, useCustomQuery } from ".";

export function useGetOMSProviders() {
  const { query, keys } = queryVars;
  const {
    data: omsProvidersData,
    isLoading: omsProvidersLoading,
    isError: omsProvidersError,
  } = useCustomQuery<OMSProvidersType>(keys.omsProviders, query.omsProviders);

  const omsProvidersOptions = useMemo(() => {
    return (
      omsProvidersData?.data?.map((oms) => ({
        label: `${oms.name}`,
        value: oms.pk,
      })) ?? []
    );
  }, [omsProvidersLoading]);

  const omsProvidersDataCode = useMemo(
    () =>
      omsProvidersData?.data?.reduce(
        (acc, { oms_code, name }) => ((acc[oms_code] = name), acc),
        {}
      ) ?? {},
    [omsProvidersLoading]
  );

  return {
    omsProvidersData,
    omsProvidersLoading,
    omsProvidersDataCode,
    omsProvidersOptions,
    omsProvidersError,
  };
}
