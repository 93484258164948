import { useEffect, useState } from "react";

export function useListState<T = unknown>(
  initialState: any,
  dependency?: any[]
) {
  const [state, setState] = useState<T[]>(initialState);

  useEffect(() => {
    setState(initialState);
  }, dependency);

  const handler = {
    setItemProp: <K extends keyof T, U extends T[K]>(
      index: number,
      prop: K,
      value: U
    ) => {
      setState((p) => {
        const clone = p.slice();
        clone[index][prop] = value;
        return clone;
      });
    },
  };
  return [state, handler] as const;
}
