import { BankListType } from "@/sample/queries";
import { useMemo } from "react";
import { useCustomQuery, queryVars } from ".";

export function useGetBankList() {
  const { query, keys } = queryVars;
  const {
    data: bankList,
    isLoading: bankListLoading,
    isError: bankListError,
  } = useCustomQuery<BankListType>(keys.bankList, query.bankList);

  const bankListOptions = useMemo(
    () =>
      bankList?.data?.map((bank) => ({
        label: bank.name,
        value: `${bank.pk}`,
      })) ?? [],
    [bankListLoading]
  );

  return {
    bankList,
    bankListLoading,
    bankListOptions,
    bankListError,
  };
}
