import { ClosingPriceMethodologyListType } from "@/sample/queries";
import { useMemo } from "react";
import { useCustomQuery, queryVars } from ".";

export function useClosingPriceMethodology() {
  const { query, keys } = queryVars;
  const {
    data: closingPriceMethodologiesData,
    isLoading: closingPriceMethodologiesLoading,
  } = useCustomQuery<ClosingPriceMethodologyListType>(
    keys.closingPriceMethodologies,
    query.closingPriceMethodologies
  );

  const closingPriceMethodologiesOptions = useMemo(() => {
    return (
      closingPriceMethodologiesData?.data?.map((price) => ({
        label: price.name,
        value: price.value,
      })) ?? []
    );
  }, [closingPriceMethodologiesLoading]);

  return {
    closingPriceMethodologiesData,
    closingPriceMethodologiesOptions,
    closingPriceMethodologiesLoading,
  };
}
