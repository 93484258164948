import { OrderTypesType } from "@/sample/queries";
import { useMemo } from "react";
import { queryVars, useCustomQuery } from ".";

export function useGetOrderTypes() {
  const { query, keys } = queryVars;
  const {
    data: orderTypesData,
    isLoading: orderTypesLoading,
  } = useCustomQuery<OrderTypesType>(keys.orderTypes, query.orderTypes);

  const orderTypesOptions = useMemo(() => {
    return (
      orderTypesData?.data?.map((type) => ({
        label: type.name,
        value: type.value,
      })) ?? []
    );
  }, [orderTypesLoading]);

  return {
    orderTypesData,
    orderTypesOptions,
    orderTypesLoading,
  };
}
