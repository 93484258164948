import { ReactNode, useEffect, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { debounce, DebouncedFunc } from "lodash";

import { Group, Loader } from "@mantine/core";
import { randomId } from "@mantine/hooks";

import { authGetter } from "@/utils/utils";
import { ClientSearchType } from "@/sample/queries";

interface SelectProps {
  label: string;
  key: string;
  value: string;
}

type UseClientSearch = [
  {
    data: SelectProps[];
    searchable: true;
    nothingFound: ReactNode | "No results found";
    onSearchChange: DebouncedFunc<(q: string) => void>;
  },
  UseQueryResult<ClientSearchType>,
  string
];

export function useClientSearch(key?: string): UseClientSearch {
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState<Array<SelectProps>>([]);

  const options = {
    enabled: !!searchValue,
  };

  const query = useQuery<ClientSearchType, "string">(
    ["client-search", key, searchValue].filter(Boolean),
    () => authGetter(`client-search?q=${searchValue}`),
    options
  );

  useEffect(() => {
    if (!searchValue || !query?.data) return;
    setData(
      query?.data?.data
        ?.sort((a, b) => {
          const prev = [a?.first_name || "", a?.last_name || ""].join(" ");
          const next = [b?.first_name || "", b?.last_name || ""].join(" ");

          return prev
            ?.toLocaleLowerCase()
            ?.localeCompare(next?.toLocaleLowerCase());
        })
        ?.map(({ first_name, last_name, cid }) => ({
          label: [first_name, last_name, "(" + cid + ")"]
            .filter(Boolean)
            .join(" "),
          key: randomId(),
          value: cid,
        })) ?? []
    );
  }, [query.isFetched, searchValue]);

  return [
    {
      data,
      searchable: true,
      nothingFound: query.isLoading ? (
        <Group px="sm" spacing="sm">
          <span>Loading...</span>
          <Loader size="sm" />
        </Group>
      ) : (
        "No results found"
      ),
      onSearchChange: debounce(
        (q: string) => setSearchValue(q.replace(/\s+\(?.*$/g, "")),
        650
      ),
    },
    query,
    searchValue,
  ];
}
