import { EventListType } from "@/sample/queries";
import { useMemo } from "react";
import { useCustomQuery, queryVars } from ".";

export function useGetEvents() {
  const { query, keys } = queryVars;
  const {
    data: eventsData,
    isLoading: eventsLoading,
  } = useCustomQuery<EventListType>(keys.events, query.events);

  const eventsOptions = useMemo(() => {
    return (
      eventsData?.data?.map((event) => ({
        label: event.name,
        value: `${event.pk}`,
      })) ?? []
    );
  }, [eventsLoading]);

  return { eventsData, eventsLoading, eventsOptions };
}
