import { Group, Pagination } from "@mantine/core";

export function TablePagination({
  pageIndex = 0,
  pageSize = 10,
  isLoading = false,
  rowLength = pageSize,
  pageCount = Math.ceil(rowLength / pageSize),
  gotoPage = (e) => {},
  rowStartIndex = pageIndex * pageSize + 1,
  rowEndIndex = rowStartIndex + pageSize - 1,
}) {
  rowEndIndex = rowEndIndex > rowLength ? rowLength : rowEndIndex;
  
  return (
    <Group hidden={!rowLength} position="apart" className="w-full">
      <span className="text-sm">
        {isLoading ? "Loading" : "Showing"} {rowStartIndex || 1} to{" "}
        {rowEndIndex || 10} of {rowLength} entries
      </span>

      <Pagination
        spacing={3}
        className="ml-auto"
        siblings={0}
        boundaries={1}
        page={pageIndex + 1}
        withControls={true}
        onChange={(p) => gotoPage(p - 1)}
        total={pageCount}
      />
    </Group>
  );
}
