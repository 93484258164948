import { Group, Text, Loader } from "@mantine/core";

export function TableLoading({
  isLoading = false,
  isError = false,
  visibleColumns,
  children,
  data,
}) {
  const Loading = () => (
    <Group position="center">
      <Text>Loading table...</Text> <Loader size="sm" />
    </Group>
  );

  const Error = () => (
    <Text>
      Error occured fetching{" "}
      <strong className="font-semibold">Table Data</strong>
    </Text>
  );

  const NoTableData = () => (
    <Text>
      <strong>No Data</strong> available in Table
    </Text>
  );

  return isLoading || !data.length ? (
    <tbody>
      <tr>
        <td className="py-3 text-center" colSpan={visibleColumns.length}>
          {isLoading ? <Loading /> : isError ? <Error /> : <NoTableData />}
        </td>
      </tr>
    </tbody>
  ) : (
    children
  );
}
