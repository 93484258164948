import { authGetter } from "@/utils/utils";

interface Query {
  deps?: null | any[];
  params?: Record<string, any>;
  keys: string | any[];
  url: string;
}

interface StaticQuery extends Query {
  static: true;
}

type UrlQuery = (item: { pageParam?: number | undefined }) => Promise<any>;

type StaticUrlQuery = () => Promise<any>;

export function useURLSearchQuery(items: StaticQuery): [any[], StaticUrlQuery];
export function useURLSearchQuery(items: Query): [any[], UrlQuery];
export function useURLSearchQuery(
  items: Query | StaticQuery
): [any[], UrlQuery | StaticUrlQuery] {
  const { queries = [], ...rest } = items.params ?? {};
  const dependencies = [
    items.keys,
    ...Object.values(rest),
    ...queries.map(({ value }) => value),
  ]
    .flat()
    .filter(Boolean);

  function staticUrlQuery() {
    const zip = items.url?.includes("?") ? "&" : "?";
    const searchParams = new URLSearchParams(rest);
    queries.forEach(({ column, value }) => {
      searchParams.append(column, value);
    });

    return authGetter(
      searchParams.toString() ? `${items.url}${zip}${searchParams}` : items.url
    );
  }

  function urlQuery({ pageParam = 1 }) {
    const zip = items.url?.includes("?") ? "&" : "?";
    const searchParams = new URLSearchParams({
      page: `${pageParam}`,
      ...rest,
    });
    queries.forEach(({ column, value }) => {
      searchParams.append(column, value);
    });
    const href = items.url + zip + searchParams;
    return authGetter(href);
  }
  return [
    items.deps ?? dependencies,
    items["static"] ? staticUrlQuery : urlQuery,
  ];
}
