import { queryClient } from "@/provider";
import { useHandleSuccess, useHandleError } from "@/hooks/utils";
import {
  authAxios,
  authDeleter,
  authGetter,
  authPoster,
  authPutter,
} from "@/utils/utils";
import { AxiosError } from "axios";
import { QueryClient, useMutation } from "react-query";

type ConfigProps = {
  auth?: boolean;
  method?: "PUT" | "POST" | "DELETE" | "GET";
  keys?: string | any[];
  url?: string;
  onSuccess?(): void;
  onSettled?(queryClient: QueryClient): void;
  onError?(err: string): void;
  hideError?: boolean;
  hideSuccess?: boolean;
  error?: {
    title?: string;
    message?: string;
  };
};

export function useCustomMutation<
  D extends {
    [key: string]: any;
    endpoint?: string;
  } | void,
  T = unknown
>({
  method,
  url = "",
  auth = true,
  keys = [],
  onError,
  onSettled,
  onSuccess,
  hideSuccess = false,
  hideError = false,
  error,
}: ConfigProps) {
  const handleSuccess = useHandleSuccess(onSuccess);
  const handleError = useHandleError({ error, onError });

  return useMutation<T, AxiosError, D, unknown>(
    (variables?: D) => {
      const { endpoint, ...data } = { ...variables, endpoint: url };

      switch (method) {
        case "PUT":
          return auth
            ? authPutter(endpoint, data)
            : authAxios.put(endpoint, data).then((res) => res?.data);
        case "DELETE":
          return auth
            ? authDeleter(endpoint)
            : authAxios.delete(endpoint).then((res) => res?.data);
        case "GET":
          return auth
            ? authGetter(endpoint)
            : authAxios.get(endpoint).then((res) => res?.data);
        default:
          return auth
            ? authPoster(endpoint, data)
            : authAxios.post(endpoint, data).then((res) => res?.data);
      }
    },
    {
      onSuccess: hideSuccess ? () => {} : handleSuccess,
      onSettled: onSettled
        ? () => onSettled?.(queryClient)
        : () => queryClient.invalidateQueries(keys),
      onError: hideError ? () => {} : handleError,
    }
  );
}
