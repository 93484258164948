import { CurrencyListDataType, CurrencyListType } from "@/sample/queries";
import { useMemo } from "react";
import { queryVars, useCustomQuery } from ".";

export function useGetCurrencies(value: keyof CurrencyListDataType = "pk") {
  const { query, keys } = queryVars;
  const {
    data: currenciesData,
    isLoading: currenciesLoading,
    isError: currenciesError,
  } = useCustomQuery<CurrencyListType>(keys.currencies, query.currencies);

  const currencyOptions = useMemo(() => {
    return (
      currenciesData?.data?.map((currency) => ({
        label: `${currency.name} (${currency.sign})`,
        value: `${currency[value]}`,
      })) ?? []
    );
  }, [currenciesLoading]);

  return {
    currenciesData,
    currencyOptions,
    currenciesLoading,
    currenciesError,
  };
}
