import { ClientWalletDataType, ClientWalletType } from "@/sample/queries";
import { useMemo } from "react";
import { showErrorNotification } from "./showErrorNotification";
import { useGetClient } from "./useGetClient";

export function useGetClientWallets(
  cid,
  oms
): { wallets: Array<ClientWalletDataType>; isLoading: boolean } {
  const {
    data: clientWallet,
    isLoading,
    isError: clientWalletError,
  } = useGetClient<ClientWalletType>("clientWallet", cid);

  const wallets = useMemo(
    () =>
      clientWallet?.data?.filter(
        ({ oms_provider }) => oms_provider?.oms_code === oms
      ) ?? [],
    [cid, isLoading, oms]
  );

  if (clientWalletError) showErrorNotification("loading Client's Wallet");
  return { wallets, isLoading };
}
