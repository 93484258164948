import { Stack, clsx } from "@mantine/core";
import { ComponentProps } from "react";
import { FilterProvider } from "./filterProvider";

interface ITableWrapper extends ComponentProps<"div"> {
  withoutProvider?: boolean;
  border?: string;
  bg?: string;
}

export function TableWrapper({
  children,
  bg = "bg-generic-background",
  border = "border border-gray-300",
  withoutProvider,
}: ITableWrapper) {
  const main = (
    <Stack
      p="md"
      className={clsx(
        "max-h-full overflow-auto rounded h-max",
        border,
        bg
      )}
      spacing="md"
    >
      {children}
    </Stack>
  );
  return withoutProvider ? main : <FilterProvider>{main}</FilterProvider>;
}
