import {
  Group,
  Stack,
  Button,
  Progress,
  Divider,
  Modal,
  Text,
  Title,
  Loader,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { Dispatch, SetStateAction, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useForm, UseFormReturnType, yupResolver } from "@mantine/form";
import { openConfirmModal } from "@mantine/modals";
import { CSVLink } from "react-csv";

import {
  queryVars,
  showErrorNotification,
  useCustomMutation,
} from "@/hooks/utils";
import {
  ExportBalanceType,
  LastDownloadedExportType,
  LastExportIdType,
} from "@/sample/queries";
import { authGetter } from "@/utils/utils";

import * as Yup from "yup";
import dayjs from "dayjs";

interface UseExportAccountBalance<T, D> {
  candidate: "account" | "wallet";
  setModalInfo: Dispatch<SetStateAction<T>>;
  modalInfo: T;
  queryHandler(
    exportForm: UseFormReturnType<{
      date: Date | null;
    }>
  ): {
    exportUrl: string;
    downloadUrl: string;
  };
}

interface IProgressBar {
  value?: number;
}

function ProgressBar({ value = 0 }: IProgressBar) {
  return (
    <Group position="apart">
      <Title size={12} weight="bold" order={4}>
        Download Progress
      </Title>
      <Progress
        size={6}
        className="flex-1 flex-shrink-0"
        value={value}
        animate
      />
      {/* <Text size="sm">
        {dayjs(lastExportId?.date).isValid()
          ? dayjs(lastExportId?.date).format("MMMM D, YYYY")
          : lastExportId?.date}
      </Text> */}
    </Group>
  );
}

interface IProgressFlow {
  progress?: LastExportIdType["detail"];
}

function ProgressFlow({ progress }: IProgressFlow) {
  if (!progress) return null;

  return (
    <>
      <Divider className="bg-gray-300" orientation="horizontal" />

      {typeof progress === "object" ? (
        progress?.percentage_done === "IN PROGRESS" ? (
          <Text size="sm" weight="bold" ml="auto">
            IN PROGRESS
          </Text>
        ) : (
          <ProgressBar
            value={parseFloat(progress?.percentage_done?.slice(0, -1) ?? "0")}
          />
        )
      ) : (
        <ProgressBar />
      )}
    </>
  );
}

export function ExportAccountBalance<
  T extends { open: boolean },
  D extends LastExportIdType
>({
  modalInfo,
  setModalInfo,
  candidate,
  queryHandler,
}: UseExportAccountBalance<T, D>) {
  const { query, keys } = queryVars;

  const handleClose = () => {
    setModalInfo((values) => ({ ...values, open: false }));
  };

  const exportForm = useForm<{
    date: Date | null;
  }>({
    initialValues: { date: new Date() },
    validate: yupResolver(
      Yup.object().shape({
        date: Yup.date()
          .required("insert an input date")
          .max(new Date(), "enter a date before today")
          .nullable(),
      })
    ),
  });

  const { downloadUrl, exportUrl } = queryHandler(exportForm);

  const { mutate: exportBalance, isLoading: exportBalanceLoading } =
    useCustomMutation<void, ExportBalanceType>({
      url: exportUrl,
      method: "GET",
      onSettled(queryClient) {
        queryClient.invalidateQueries(keys.lastExportId(candidate));
      },
    });

  const [lastExportedDate, setLastExportedDate] = useState<
    string | undefined
  >();

  const dateToBeExported = dayjs(exportForm.values.date).format("MMMM D, YYYY");

  const [csvData, setCSVData] = useState("");
  const { mutate: lastDownloadedExport } =
    useMutation<LastDownloadedExportType>(
      keys.lastDownloadedExport(candidate, lastExportedDate),
      () => authGetter(downloadUrl),
      {
        onSettled(data) {
          if (typeof data === "object") showErrorNotification(data.message);
          else setCSVData(data ?? "");
        },
      }
    );

  const { data: lastExportId } = useQuery<D>(
    keys.lastExportId(candidate),
    () => authGetter(query.lastExportId(candidate)),
    {
      onSettled(data) {
        if (data?.state === "PENDING" && data?.detail === "None") {
          setLastExportedDate(data?.date);
          lastDownloadedExport();
        } else if (data?.state === "SUCCESS" || "PROGRESS")
          setLastExportedDate(data?.date);
        if (data?.state === "SUCCESS") lastDownloadedExport();
      },
      refetchOnWindowFocus: false,
      refetchInterval(data) {
        const { state } = { ...data };
        return state === "PENDING" ? 4000 : state === "PROGRESS" ? 1000 : false;
      },
    }
  );

  return (
    <Modal
      title="Export"
      withCloseButton={false}
      opened={modalInfo["open"]}
      onClose={handleClose}
    >
      <Stack spacing="md">
        <Group spacing="xs">
          <DatePicker
            classNames={{
              yearPickerControlActive: "bg-accent-90",
              monthPickerControlActive: "bg-accent-90",
              root: "flex-1",
            }}
            maxDate={new Date()}
            placeholder="Select Date"
            {...exportForm.getInputProps("date")}
          />
          <Button
            className="text-black border border-generic-placeholder hover:bg-generic-foreground"
            onClick={handleClose}
          >
            Close
          </Button>

          <Button
            disabled={
              !exportForm.values.date || lastExportId?.state !== "SUCCESS"
            }
            loading={exportBalanceLoading}
            className="text-white bg-accent-90"
            onClick={() => {
              if (["PROGRESS", "PENDING"].includes(lastExportId?.state ?? "")) {
                openConfirmModal({
                  title: <Title order={5}>Export Balances</Title>,
                  children: (
                    <Text>
                      Are you sure you want to export {candidate} balances for{" "}
                      {dateToBeExported}? Doing so would replace the current
                      export.
                    </Text>
                  ),
                  styles: {
                    modal: {
                      alignSelf: "center",
                    },
                  },
                  cancelProps: {
                    children: "Cancel",
                  },
                  confirmProps: {
                    children: "Confirm",
                    className: "text-white bg-accent-90",
                  },

                  onConfirm: exportBalance,
                });
              } else exportBalance();
            }}
          >
            Export
          </Button>
        </Group>

        <>
          <Divider className="bg-gray-300" orientation="horizontal" />

          {csvData ? (
            <Group position="apart">
              <Stack spacing={0}>
                <Title size={12} weight="bold" order={4}>
                  Last Exported
                </Title>
                <Text size="md">
                  {dayjs(lastExportedDate).isValid()
                    ? dayjs(lastExportedDate).format("MMMM D, YYYY")
                    : lastExportedDate}
                </Text>
              </Stack>

              <CSVLink
                data={csvData}
                // className={clsx({
                //   "pointer-events-none cursor-default":
                //     lastExportId?.state !== "SUCCESS",
                // })}
                filename={`${candidate.toLocaleLowerCase()}_${
                  lastExportId?.date
                }`}
              >
                <Button
                  // disabled={lastExportId?.state !== "SUCCESS"}
                  className="text-white bg-accent-90"
                >
                  Download CSV
                </Button>
              </CSVLink>
            </Group>
          ) : (
            <Group position="center">
              <Loader size="sm" />
            </Group>
          )}
        </>

        {["PROGRESS", "PENDING"].includes(lastExportId?.state ?? "") && (
          <ProgressFlow progress={lastExportId?.detail} />
        )}
      </Stack>
    </Modal>
  );
}
