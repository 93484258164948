import { forwardRef } from "react";
import {
  Button,
  ButtonProps,
  clsx,
  createPolymorphicComponent,
} from "@mantine/core";

interface MetaButtonProps extends Omit<ButtonProps, "className" | "size"> {}

const _Button = forwardRef<HTMLButtonElement, MetaButtonProps>(
  ({ children, color = "bg-primary-90 hover:bg-primary-80", ...others }, ref) => (
    <Button
      classNames={{
        root: clsx("px-4 py-2 text-sm text-accent-0", color),
      }}
      ref={ref}
      {...others}
    >
      {children}
    </Button>
  )
);

_Button.displayName = "MetaButton";
export const MetaButton = createPolymorphicComponent<"button", MetaButtonProps>(
  _Button
);
