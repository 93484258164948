import { useFilterFormContext } from "@/components";
import { useEffect } from "react";

export function useFilterList(list, column) {
  const filterForm = useFilterFormContext();
  useEffect(() => {
    list.forEach((item) => {
      const { checked = false, key = "", value = "" } = { ...item };
      const idx = filterForm.values.queries.findIndex(
        ({ key: queryKey }) => queryKey === key
      );
      if (idx !== -1) {
        if (!checked) filterForm.removeListItem("queries", idx);
        return;
      }
      if (checked) {
        filterForm.insertListItem("queries", { column, value, key });
      }
    });
  }, [list]);
}
