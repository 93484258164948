import { FormEventHandler, forwardRef } from "react";
import { Stack, StackProps, createPolymorphicComponent } from "@mantine/core";

interface FormProps extends Omit<StackProps, "onSubmit"> {
  onSubmit?: FormEventHandler<HTMLFormElement>;
}

const _Stack = forwardRef<HTMLFormElement, FormProps>(
  ({ children, onSubmit, ...others }, ref) => (
    <form onSubmit={onSubmit} ref={ref}>
      <Stack px="md" spacing="md" {...others}>
        {children}
      </Stack>
    </form>
  )
);

_Stack.displayName = "Form";
export const Form = createPolymorphicComponent<"form", FormProps>(_Stack);
