import { CommodityListType } from "@/sample/queries";
import { useMemo } from "react";
import { queryVars, useCustomQuery } from ".";

export function useGetCommodities() {
  const { query, keys } = queryVars;
  const {
    data: commoditiesData,
    isLoading: commoditiesLoading,
  } = useCustomQuery<CommodityListType>(keys.commodities, query.commodities);

  const commoditiesOptions = useMemo(() => {
    return (
      commoditiesData?.data?.map((board) => ({
        label: `${board.name} (${board.code})`,
        value: `${board.pk}`,
      })) ?? []
    );
  }, [commoditiesLoading]);

  return {
    commoditiesData,
    commoditiesLoading,
    commoditiesOptions,
  };
}
