import { BoardsDataType, BoardsType } from "@/sample/queries";
import { useMemo } from "react";
import { useCustomQuery, queryVars } from ".";

type BoardField = keyof BoardsType["data"][number];

export function useGetBoards(value: BoardField = "pk") {
  const { query, keys } = queryVars;
  const { data: boardsData, isLoading: boardsLoading } =
    useCustomQuery<BoardsType>(keys.boards, query.boards);

  const boardsOptions = useMemo(() => {
    return (
      boardsData?.data?.map((board) => ({
        label: board.name,
        value: `${board[value]}`,
      })) ?? []
    );
  }, [boardsLoading]);

  const boardsPk: Record<string, BoardsDataType> = useMemo(() => {
    const boards = boardsData?.data?.map((board) => [board.pk, board]);
    return boards ? Object.fromEntries(boards) : {};
  }, [boardsLoading]);

  return {
    boardsData,
    boardsLoading,
    boardsOptions,
    boardsPk,
  };
}
