import { randomId, useListState, UseListStateHandlers } from "@mantine/hooks";
import { useEffect } from "react";
import { Column } from "react-table";

export type ContextValues<D extends object> = {
  label: Column<D>["Header"];
  key: Column<D>["accessor"] | string;
  checked: boolean;
};

type UseColumnsProps<D extends object> = {
  columns: Array<Column<D>>;
  setHiddenColumns(columns: Array<string>): void;
  name: string;
} & ({ hiddenColumns: Array<string> } | { visibleColumns: Array<string> });

export function useColumns<D extends object>({
  columns,
  setHiddenColumns,
  name,
  ...props
}: UseColumnsProps<D>) {
  const checkboxData = columns.map(({ Header, accessor }) => ({
    label: Header,
    key: accessor || randomId(),
    checked:
      "hiddenColumns" in props // type-guard
        ? !props.hiddenColumns.includes(accessor as string)
        : props.visibleColumns.includes(accessor as string),
  }));

  const pref = `ecn.user.preference.table_column.${name}`;
  if (!localStorage.getItem(pref))
    localStorage.setItem(pref, JSON.stringify(checkboxData));

  const [values, handlers] = useListState<ContextValues<D>>(
    JSON.parse(localStorage.getItem(pref) ?? JSON.stringify(checkboxData))
  );

  useEffect(() => {
    localStorage.setItem(pref, JSON.stringify(values));
  }, [values]);

  useEffect(() => {
    setHiddenColumns(
      values
        .filter((item) => !item.key || !item.checked)
        .map((item) => item.key) as string[]
    );
  }, [values]);

  return [values, handlers] as [
    Array<ContextValues<D>>,
    UseListStateHandlers<ContextValues<D>>
  ];
}
