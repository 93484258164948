import { Paper, PaperProps } from "@mantine/core";
import { CSSProperties } from "react";

import GridItem from "./gridItem";

interface IGrid extends PaperProps {
  flow?: CSSProperties["gridAutoFlow"];
  breakpoint?: number | string;
  span?: boolean;
  bg?: CSSProperties["backgroundColor"];
  cols?: number;
  spacing?: CSSProperties["gap"];
  switchpoint?: number;
}

export function Grid({
  span,
  children,
  breakpoint,
  flow = "column",
  spacing = 15,
  style,
  cols,
  bg = "transparent",
  ...props
}: IGrid) {
  const GridStyle = {
    display: "grid",
    backgroundColor: bg,
    gridAutoColumns: "1fr",
    ...(flow && { gridAutoFlow: flow }),
    gap: spacing,
    ...(breakpoint && {
      gridTemplateColumns: `repeat(${
        span ? "auto-fit" : "auto-fill"
      }, minmax(min(${breakpoint}px, 100%), 1fr))`,
    }),
    ...(cols && {
      gridTemplateColumns: `repeat(${cols}, minmax(${breakpoint || 0}, 1fr))`,
    }),
    ...style,
  };

  return (
    <Paper style={GridStyle} {...props}>
      {children}
    </Paper>
  );
}

Grid.Item = GridItem;
Grid.displayName = "Grid";
