import { createElement, forwardRef } from "react";
import { CSSObject, useCss } from "@mantine/styles";

interface GridItem extends React.HTMLAttributes<"div"> {
  styles?: CSSObject;
  component?: keyof JSX.IntrinsicElements;
  colStart?: number;
  colSpan?: "full" | number;
  colEnd?: number;
  rowStart?: number;
  rowSpan?: "full" | number;
  rowEnd?: number;
}

function GridItemWithoutRef(
  {
    component,
    className,
    children,
    colStart,
    colSpan,
    colEnd,
    rowStart,
    rowSpan,
    rowEnd,
    styles,
    ...rest
  }: GridItem,
  ref
) {
  const { css, cx } = useCss();
  const props = {
    component: component || "div",
    className: cx(className, css(styles)),
    style: {
      gridColumn:
        colSpan === "full" ? "1 / -1" : `span ${colSpan} / span ${colSpan}`,
      gridColumnStart: colStart,
      gridColumnEnd: colEnd,
      gridRow:
        rowSpan === "full" ? "1 / -1" : `span ${rowSpan} / span ${rowSpan}`,
      gridRowStart: rowStart,
      gridRowEnd: rowEnd,
    },
    ...rest,
    ref,
  };
  const Element = props.component;
  return createElement(Element, props, children);
}

const GridItem = forwardRef(GridItemWithoutRef);
GridItem.displayName = "GridItem";
export default GridItem;
