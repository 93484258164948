import { queryVars, useCustomMutation } from "@/hooks/utils";
import { clsx, Group, Stack } from "@mantine/core";
import { NOTIFICATION_PAGES } from "@/utils/config";

import dayjs from "dayjs";
import Link from "next/link";

interface INotificationItem {
  name: string;
  time: string;
  message: string;
  category: string;
  nid: string;
  is_read: boolean;
}

export function NotificationItem({
  name,
  time,
  nid,
  message,
  category,
  is_read,
}: INotificationItem) {
  const title = category?.replace(/_/g, " ");
  const extract = /\w+/.exec(category)?.at(0) ?? "";

  const { mutations, keys } = queryVars;
  const { mutate: markAsRead, isLoading: markAsReadLoading } =
    useCustomMutation({
      method: "POST",
      url: mutations.markNotificationAsRead(nid),
      keys: keys.notificationList.concat([nid]),
      onSettled(queryClient) {
        queryClient.invalidateQueries(keys.notificationList);
      },
      hideSuccess: true,
      hideError: true,
    });

  return (
    <Link href={NOTIFICATION_PAGES[extract] ?? ""} passHref>
      <a>
        <Stack
          spacing="sm"
          className={clsx(
            "hover:bg-gray-100 hover:scale-[1.01] hover:border-gray-400 duration-100",
            "grid gap-3 px-6 py-5 text-sm border rounded-2xl auto-rows-max bg-gray-50"
          )}
          onMouseEnter={() => {
            if (!is_read && !markAsReadLoading) markAsRead();
          }}
        >
          <Group position="apart" spacing="sm">
            <Stack spacing="xs">
              {/* {name && <p className="text-[#49474D] font-bold">{name}</p>} */}
              <h3 className="font-semibold break-words text-[#49474D]">
                {title}
              </h3>
            </Stack>
            <p className="text-[#8F8E91] text-xs font-normal">
              {dayjs(time).format("h:mm A")}
            </p>
          </Group>
          <Group spacing="md" align="end" noWrap>
            <p className="text-[#5D5B60] font-normal pt-2">{message}</p>
            <small
              className="w-1.5 h-1.5 flex-shrink-0 mb-1 rounded-full"
              style={{
                backgroundColor: is_read ? "#38CB89" : "deeppink",
              }}
            />
          </Group>
        </Stack>
      </a>
    </Link>
  );
}
