export function NumberFormatter({ children, row, ...props }) {
  return (
    <span
      {...row.getRowProps({
        style: {
          textAlign: "end",
          display: "block",
          width: "75%",
          margin: "auto",
        },
      })}
      {...props}
    >
      {new Intl.NumberFormat().format(children)}
    </span>
  );
}
