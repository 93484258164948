import { LogisticsOfficerListType } from "@/sample/queries";
import { useMemo } from "react";
import { useCustomQuery, queryVars } from ".";

export function useGetLogisiticsOfficerList() {
  const { query, keys } = queryVars;
  const {
    data: logisticsOfficersList,
    isLoading: logisticsOfficersListLoading,
    isError: logisticsOfficersListError,
  } = useCustomQuery<LogisticsOfficerListType>(
    keys.logisticsOfficers,
    query.logisticsOfficers
  );

  const logisticsOfficers = useMemo(() => {
    return (
      logisticsOfficersList?.data?.map(({ full_name, location, pk }) => ({
        label: location ? `${full_name} - (${location})` : full_name,
        value: `${pk}`,
      })) ?? []
    );
  }, [logisticsOfficersListLoading]);

  return {
    logisticsOfficersList,
    logisticsOfficers,
    logisticsOfficersListLoading,
    logisticsOfficersListError,
  };
}
