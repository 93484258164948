import { Group, Text, Tooltip } from "@mantine/core";
import { InfoCircle } from "iconsax-react";

interface IFormTip {
  children: string;
  title: string;
}

export function FormTip({ children, title }: IFormTip) {
  return (
    <Group align="flex-end" spacing="xs">
      <Text>{title}</Text>
      <Tooltip.Floating maw={250} label={children} multiline>
        <InfoCircle className="mb-[0.15rem]" size={14} />
      </Tooltip.Floating>
    </Group>
  );
}
