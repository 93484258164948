export function NotesWrapper({ children }) {
  return (
    <div
      style={{
        width: "max-content",
        maxWidth: 350,
        whiteSpace: "normal",
        wordBreak: "break-word",
      }}
    >
      {children}
    </div>
  );
}
