import { SVGProps } from "react";

export default function StartDate(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.1343 11.0396L13.2638 7.39908C13.2115 7.33262 13.1448 7.27888 13.0687 7.2419C12.9926 7.20492 12.9091 7.18565 12.8245 7.18555H11.6903C11.5731 7.18555 11.5083 7.32032 11.5801 7.41308L14.1057 10.6161H3.51307C3.43606 10.6161 3.37305 10.6791 3.37305 10.7561V11.8063C3.37305 11.8833 3.43606 11.9463 3.51307 11.9463H15.6932C16.1623 11.9463 16.4231 11.4072 16.1343 11.0396Z"
        fill="currentColor"
      />
    </svg>
  );
}