import {
  Group,
  Button,
  Select,
  CloseButton,
  ScrollArea,
  Divider,
  Popover,
  Stack,
  Input,
  Box,
  clsx,
  Checkbox,
} from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { useForm, yupResolver } from "@mantine/form";
import { randomId } from "@mantine/hooks";
import { Column } from "react-table";
import { RangeCalendar } from "@mantine/dates";
import { FilterSearch } from "iconsax-react";

import StartDate from "@/svg/StartDate";
import customParseFormat from "dayjs/plugin/customParseFormat";

import dayjs from "dayjs";
dayjs.extend(customParseFormat);

import * as Yup from "yup";
import { useFilterFormContext } from "./filterProvider";

export interface QueryItem {
  column: string;
  value: string;
  key: string;
}

export interface FilterFormType {
  search: string;
  queries: Array<QueryItem>;
  pageSize: string;
}

interface MultiColumnFilterProps<D extends object> {
  columns: Array<Column<D>>;
  omit: Array<string>;
  value: any;
  hideSearchBar: boolean;
  hideMultiColumnFilter: boolean;
  onChange(event: any): void;
  dataOptions: Record<Extract<keyof D, string>, any[]>;
  dateFormat: string;
  error?: React.ReactNode;
}

interface SelectFormType {
  dateRange: [Date | null, Date | null];
  data: Array<string>;
}

export function isISO8601DateString(str = "") {
  const validity = dayjs(
    (str as unknown as string).substring(0, 23),
    ["YYYY-MM-DD", "YYYY-MM-DD[T]HH:mm:ss.SSS", "YYYY-MM-DD HH:mm:ss.SSS"],
    true
  ).isValid();
  return validity;
}

function formatColumns<D extends object>(columns: Array<Column<D>>, omit) {
  return columns
    .slice()
    .sort((a, b) => {
      return (a.Header as string) > (b.Header as string) ? 1 : -1;
    })
    .flatMap(({ Header, accessor }) => {
      return !omit.includes(accessor as string)
        ? {
            label: Header as string,
            value: accessor as string,
            key: accessor,
          }
        : [];
    });
}

function transformText(str) {
  return str
    .replace(/_+/g, " ")
    .replace(/(?<=(^|\s))\w/g, (e) => e.toLocaleUpperCase());
}

export function MultiColumnFilter<D extends object>({
  columns,
  dataOptions,
  hideMultiColumnFilter,
  hideSearchBar,
  dateFormat,
  omit,
  ...inputProps
}: MultiColumnFilterProps<D>) {
  const [opened, setOpened] = useState(false);

  const filterForm = useFilterFormContext();

  const columnsForm = useForm<QueryItem>({
    initialValues: {
      column: "",
      value: "",
      key: randomId(),
    },
    validate: yupResolver(
      Yup.object().shape({
        column: Yup.string().required("Select a column"),
        value: Yup.string().required("Input a query value"),
      })
    ),
  });

  const selectForm = useForm<SelectFormType>({
    initialValues: {
      dateRange: [null, null],
      data: [],
    },
  });

  const data = dataOptions[columnsForm.values.column];

  useEffect(() => {
    if (!columnsForm.values.column || isValidDate) return;
    columnsForm.setFieldValue("value", "");
    selectForm.setFieldValue("data", data ?? []);
  }, [dataOptions, columnsForm.values.column]);

  const isValidDate = useMemo(
    () =>
      (data ?? [""]).every(
        (e) => typeof e === "string" && isISO8601DateString(e)
      ),
    [columnsForm.values.column]
  );

  const isBoolean = useMemo(
    () => (data ?? [""]).every((e) => e == "true" || e == "false"),
    [columnsForm.values.column]
  );

  return (
    <Popover
      withinPortal
      closeOnEscape
      opened={opened}
      onClose={() => setOpened((e) => false)}
      offset={5}
      position="bottom-start"
      width={isValidDate ? undefined : 260}
      classNames={{
        dropdown: "border border-gray-300 p-3",
      }}
    >
      <Popover.Target>
        <Group spacing="sm">
          {hideSearchBar ? null : (
            <Input
              className="border-none"
              {...inputProps}
              placeholder="Enter search term"
            />
          )}
          {hideMultiColumnFilter ? null : (
            <Button
              onClick={() => setOpened((o) => !o)}
              px="xs"
              variant="light"
              className="bg-white border-gray-300 text-primary-90 hover:border-accent-80 hover:text-accent-80 focus:border-accent-80"
              leftIcon={<FilterSearch size={20} />}
            >
              {filterForm.values.queries.length}
            </Button>
          )}
        </Group>
      </Popover.Target>
      <Popover.Dropdown>
        <div className="flex items-center gap-4 transition-all duration-300">
          <Stack className="w-full max-w-[250px]" spacing="sm">
            <Select
              searchable
              data={formatColumns<D>(columns, omit)}
              placeholder="Select Column"
              {...columnsForm.getInputProps("column")}
              classNames={{
                dropdown: "min-w-max !left-0",
                root: "relative",
              }}
            />

            {isBoolean ? (
              <Checkbox
                size="md"
                label={columnsForm.values.value || "false"}
                checked={columnsForm.values.value === "true" ? true : false}
                onChange={(e) => {
                  columnsForm.setFieldValue(
                    "value",
                    e.currentTarget.checked ? "true" : "false"
                  );
                }}
              />
            ) : isValidDate ? (
              <Button
                px="sm"
                component="label"
                className={
                  "bg-white text-black hover:!bg-white border-gray-300 focus:border-accent-80 w-full"
                }
                classNames={{
                  label: "gap-2",
                }}
              >
                <span
                  className={clsx(
                    !selectForm.values.dateRange[0] &&
                      "text-generic-placeholder"
                  )}
                >
                  {selectForm.values.dateRange[0]
                    ? dayjs(selectForm.values.dateRange[0]).format(dateFormat)
                    : "Start date"}
                </span>
                <StartDate className="text-xl text-primary-30" />
                <span
                  className={clsx(
                    !selectForm.values.dateRange[1] &&
                      "text-generic-placeholder"
                  )}
                >
                  {selectForm.values.dateRange[1]
                    ? dayjs(selectForm.values.dateRange[1]).format(dateFormat)
                    : "End date"}
                </span>
              </Button>
            ) : (
              <Select
                {...columnsForm.getInputProps("value")}
                classNames={{
                  dropdown: "min-w-max !left-0",
                  root: "relative",
                }}
                data={selectForm.values.data}
                placeholder="Input Query Value"
                nothingFound="Nothing found"
                onCreate={(query) => {
                  const item = { value: query, label: query };
                  selectForm.insertListItem("data", query);
                  return item;
                }}
                getCreateLabel={(query) => `${query}`}
                searchable
                creatable
              />
            )}

            <Button
              variant="filled"
              className="bg-accent-90 min-w-[225px]"
              type="button"
              onClick={() => {
                if (isValidDate) {
                  selectForm.values.dateRange.forEach((time, i) => {
                    if (time) {
                      filterForm.insertListItem("queries", {
                        column: `${columnsForm.values.column}_${
                          !i ? "start" : "end"
                        }`,
                        value: dayjs(time).format(dateFormat),
                        key: randomId(),
                      });
                    }
                  });
                  selectForm.reset();
                  return columnsForm.reset();
                }
                if (!columnsForm.validate().hasErrors) {
                  filterForm.insertListItem("queries", columnsForm.values);
                  columnsForm.reset();
                }
              }}
            >
              Add
            </Button>
            <hr className="border-[#ced4da]" />
            {filterForm.values.queries.length ? (
              <ScrollArea scrollbarSize={6} scrollHideDelay={0} type="always">
                <Group
                  p="xs"
                  mb="sm"
                  className="border-y border-y-[#ced4da] bg-primary-40/5"
                  spacing="xs"
                  noWrap
                >
                  {filterForm.values.queries.map(
                    ({ column, value, key }, index) => (
                      <Box
                        key={key}
                        className="overflow-hidden border border-accent-90 bg-accent-90/5"
                        sx={(theme) => ({
                          display: "flex",
                          borderRadius: theme.radius.sm,
                          cursor: "default",
                          justifyContent: "space-between",
                          alignItems: "center",
                          alignSelf: "stretch",
                        })}
                      >
                        <Box
                          mx="md"
                          sx={{
                            lineHeight: 1,
                            fontSize: 12,
                            color: "black",
                            width: "max-content",
                          }}
                        >
                          {`${transformText(column)}=${value}`}
                        </Box>
                        <Divider
                          className="border-accent-90"
                          orientation="vertical"
                        />
                        <CloseButton
                          radius="xs"
                          onClick={() => {
                            filterForm.removeListItem("queries", index);
                          }}
                          className="text-black hover:bg-accent-90/30 hover:text-accent-90"
                          variant="transparent"
                          iconSize={14}
                          tabIndex={-1}
                          size={30}
                        />
                      </Box>
                    )
                  )}
                </Group>
              </ScrollArea>
            ) : null}
          </Stack>

          {isValidDate && (
            <RangeCalendar
              {...selectForm.getInputProps("dateRange", {
                type: "input",
              })}
            />
          )}
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}
