import { showNotification } from "@mantine/notifications";
import { useEffect } from "react";

export function useErrorNotification(isError, event: string) {
  useEffect(() => {
    if (!isError) return;
    showNotification({
      title: "Operation failed",
      message: `An error has occurred while ${event}`,
      color: "red",
    });
  }, [isError]);
}
