import { isValidElement, useEffect, useMemo, useState } from "react";
import {
  Group,
  Popover,
  Stack,
  Checkbox,
  Button,
  Menu,
  Indicator,
} from "@mantine/core";
import { ArrowDown2, ExportSquare, Eye, Printer } from "iconsax-react";
import {
  useListState,
  UseListStateHandlers,
  useLocalStorage,
} from "@mantine/hooks";
import { CSVLink } from "react-csv";
import { useInfiniteQuery } from "react-query";

import { ContextValues } from "@/hooks/utils/useColumns";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";
import { isISO8601DateString } from "./multiColumnFilter";
import { _dateFormatter } from "@/utils/lib";
import { HookArgs, QueryResult, useURLSearchQuery } from "@/hooks/utils";
import { queryClient } from "@/provider";

interface ContextBarProps<T extends object> {
  args: HookArgs;
  values: Array<ContextValues<T>>;
  handlers: UseListStateHandlers<ContextValues<T>>;
  filename: string;
  filterer: (
    data: Array<{}>,
    serializer?: ((e: number) => number) | undefined
  ) => T[];
  data: Array<T>;
}

export function ContextBar<D extends QueryResult, T extends object>({
  args,
  values,
  handlers,
  filterer,
  filename,
}: ContextBarProps<T>) {
  const [innerValues, innerHandler] = useListState(values);
  const [opened, setOpened] = useState(false);
  const [exportAsCSV, setExportAsCSV] = useState(false);
  const [showExportProgress, setShowExportProgress] = useState(false);

  const [queryKeys, urlQuery] = useURLSearchQuery({
    ...args,
    deps: ["csv-export", filename],
    params: {
      ...args.params,
      page_size: "50",
    },
  });

  const { data, ...props } = useInfiniteQuery<D>(queryKeys, urlQuery, {
    keepPreviousData: true,
    optimisticResults: false,
    refetchOnWindowFocus: false,
    enabled: exportAsCSV,
    staleTime: Infinity,
    getNextPageParam(lastPage, pages) {
      return lastPage?.next ? pages.length + 1 : null;
    },
  });

  useEffect(() => {
    if (!props.isFetching) {
      if (props.hasNextPage) props.fetchNextPage();
      else setExportAsCSV(false);
    }
  }, [props.isFetching]);

  function handleCheck(event, index) {
    return innerHandler.setItemProp(
      index,
      "checked",
      event.currentTarget.checked
    );
  }

  function getInnerText(el: any) {
    if (isISO8601DateString(String(el))) return _dateFormatter(el);
    if (isValidElement<any>(el)) return getInnerText(el.props.children);
    return el?.toString();
  }

  const refinedHeaders = useMemo(
    () =>
      innerValues
        .filter(({ checked, key }) => checked && key !== "action")
        .map(({ label, key }) => ({ label, key })) as Array<LabelKeyObject>,
    []
  );

  const refinedData = useMemo(() => {
    const stack = data?.pages?.flatMap((page) => page?.data ?? []);
    let filter;

    try {
      filter = filterer(stack ?? [])?.map((e) => {
        const entries = Object.entries(e)
          .filter(
            ([key, value]) =>
              refinedHeaders.map(({ key }) => key).includes(key) &&
              ![null, undefined].includes(value)
          )
          .map(([key, value]) => [key, getInnerText(value)]);
        return Object.fromEntries(entries);
      });
    } catch (e) {
      console.log(e);
    }

    return filter;
  }, [exportAsCSV]);

  return (
    <Group>
      <Popover
        withinPortal
        opened={opened}
        onClose={() => setOpened((e) => false)}
        offset={5}
        position="bottom-start"
        classNames={{
          dropdown: "w-max max-h-[360px] overflow-scroll p-0 rounded-md",
        }}
      >
        <Popover.Target>
          <Button
            variant="white"
            className="ecn-button"
            onClick={() => setOpened((o) => !o)}
            leftIcon={<Eye variant="Outline" size="16" />}
            rightIcon={<ArrowDown2 variant="Bold" size="16" />}
          >
            Columns
          </Button>
        </Popover.Target>
        <Popover.Dropdown className="flex flex-col bg-white border-gray-300">
          <Stack
            className="flex-1 overflow-auto"
            pl="sm"
            py="sm"
            pr={56}
            spacing="md"
          >
            {(innerValues ?? []).map((value, index) => (
              <Checkbox
                key={index}
                label={value.label}
                checked={value.checked}
                onChange={(event) => handleCheck(event, index)}
              />
            ))}
          </Stack>
          <Group
            className="border-t border-gray-300 bg-generic-background"
            p="xs"
            spacing="xs"
            grow
          >
            <Button
              className="ecn-button min-w-max"
              onClick={() => {
                if (innerValues.every(({ checked }) => checked)) {
                  innerHandler.setState(values);
                } else {
                  innerValues?.map((_, index) => {
                    innerHandler?.setItemProp(index, "checked", true);
                  });
                }
              }}
            >
              {innerValues.every(({ checked }) => checked)
                ? "Reset"
                : "Select All"}
            </Button>
            <Button
              variant="outline"
              className="hover:text-white hover:bg-accent-90 text-accent-90 bg-accent-10"
              onClick={() => handlers.setState(innerValues)}
            >
              Update
            </Button>
          </Group>
        </Popover.Dropdown>
      </Popover>
      <Button
        variant="white"
        className="ecn-button"
        leftIcon={<Printer size={16} />}
      >
        Print
      </Button>
      <Menu position="bottom-end">
        <Menu.Target>
          <Indicator
            inline
            classNames={{
              indicator: "p-0",
            }}
            color={showExportProgress ? "#e1261c" : "transparent"}
            processing={exportAsCSV ? true : false}
            label={
              showExportProgress ? (
                exportAsCSV ? (
                  ""
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 15 15"
                  >
                    <path
                      fill="white"
                      fillRule="evenodd"
                      d="m11.39 5.312l-4.318 5.399L3.68 7.884l.64-.768l2.608 2.173l3.682-4.601l.78.624Z"
                      clipRule="evenodd"
                    />
                  </svg>
                )
              ) : null
            }
            size={exportAsCSV ? 12 : 16}
          >
            <Button
              variant="white"
              className="ecn-button"
              leftIcon={<ExportSquare size={16} />}
              rightIcon={<ArrowDown2 variant="Bold" size="16" />}
            >
              Export
            </Button>
          </Indicator>
        </Menu.Target>

        <Menu.Dropdown>
          {refinedData?.length ? (
            <CSVLink
              data={refinedData ?? []}
              headers={refinedHeaders ?? []}
              filename={filename ?? ""}
            >
              <Menu.Item
                onClick={() => {
                  setShowExportProgress(false);
                  queryClient.resetQueries("csv-export");
                }}
              >
                Download CSV
              </Menu.Item>
            </CSVLink>
          ) : (
            <Menu.Item
              disabled={props.isFetching}
              onClick={() => {
                setExportAsCSV(true);
                setShowExportProgress(true);
              }}
            >
              Export to CSV
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}
