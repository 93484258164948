import { TableProps } from "@mantine/core";
import { Column } from "react-table";
import { useCallback, useRef } from "react";
import {
  ContextBar,
  TableLoading,
  TablePagination,
  useMakeTable,
  ITBody,
  ITHead,
  SideEffects,
  SubComponentRenderType,
} from "@/components";
import { useColumns, HookArgs, QueryResult } from ".";
import { useIncrementalQuery } from "./useIncrementalQuery";

interface CustomTableProps {
  headProps: ITHead;
  bodyProps: ITBody;
  tableProps: TableProps;
}

interface UseIncrementalQueryTable<D extends QueryResult, T extends object> {
  columns: Array<Column<T>>;
  hiddenColumns: Array<string>;
  sideEffect?: SideEffects;
  dependencies?: Array<any>;
  renderRowSubComponent?: SubComponentRenderType<T>;
  dataFilterer: (
    data: D["data"],
    serializer?: (e: number) => number
  ) => Array<T>;
  hookArgs: HookArgs;
  name: string;
}

export function useIncrementalQueryTable<
  D extends QueryResult,
  T extends object
>({
  columns,
  hiddenColumns,
  sideEffect,
  dependencies = [],
  renderRowSubComponent = () => null,
  dataFilterer,
  hookArgs,
  name,
}: UseIncrementalQueryTable<D, T>) {
  const pageIndex = useRef(1);

  const { page_size = 10 } = hookArgs.params;
  const { data, fetchedData, ...props } = useIncrementalQuery<D, T>({
    pageIndex,
    dependencies,
    dataFilterer,
    hookArgs,
  });

  const {
    Table,
    visibleColumns,
    state: { pageIndex: page, pageSize },
    setHiddenColumns,
    setPageSize,
    ...tableProps
  } = useMakeTable({
    data,
    columns,
    renderRowSubComponent,
    initialState: {
      hiddenColumns,
      pageIndex: pageIndex.current - 1,
      pageSize: +page_size,
    },
    sideEffect,
  });

  // TABLE DISPLAY
  function CustomTable({
    headProps = {},
    bodyProps = {},
    tableProps = {},
  }: Partial<CustomTableProps>) {
    return (
      <div className="flex-1 h-full overflow-auto">
        <Table
          highlightOnHover
          verticalSpacing="md"
          fontSize="sm"
          className="h-full overflow-auto text-primary-90 whitespace-nowrap"
          {...tableProps}
        >
          <Table.Head
            classNames={{
              thead: "bg-generic-foreground sticky top-0 z-10",
            }}
            {...headProps}
          />
          <TableLoading
            isError={props.isError}
            visibleColumns={visibleColumns}
            isLoading={props.isFetching}
            data={data}
          >
            <Table.Body
              classNames={{
                tbody: "overflow-auto align-baseline",
              }}
              {...bodyProps}
            />
          </TableLoading>
        </Table>
      </div>
    );
  }

  // TABLE PAGINATION
  const { isFetching, fetchNextPage, fetchPreviousPage } = props;
  const CustomPagination = useCallback(() => {
    return !data?.length ? null : (
      <TablePagination
        pageIndex={page}
        isLoading={isFetching}
        {...{
          gotoPage: (page) => {
            pageIndex.current = page + 1;
            fetchNextPage({ pageParam: page + 1 });
          },
          previousPage: fetchPreviousPage,
          nextPage: fetchNextPage,
          rowLength: fetchedData?.pages?.at(0)?.count,
          pageSize: +page_size,
        }}
      />
    );
  }, [page, isFetching, fetchNextPage, fetchPreviousPage, page_size]);

  // COLUMNS FILTERING
  const [values, handlers] = useColumns<T>({
    columns,
    hiddenColumns,
    setHiddenColumns,
    name,
  });

  // TABLE CONTEXT_BAR
  const CustomContextBar = useCallback(() => {
    return (
      <ContextBar<D, T>
        filename={name}
        values={values}
        filterer={dataFilterer}
        args={hookArgs}
        handlers={handlers}
        data={data}
      />
    );
  }, [values, hookArgs]);

  return {
    values,
    handlers,
    setPageSize,
    setHiddenColumns,
    CustomPagination,
    CustomContextBar,
    CustomTable,
    tableData: data,
    fetchedData,
    pageSize,
    ...props,
    ...tableProps,
  };
}
