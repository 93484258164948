import { forwardRef } from "react";
import {
  createPolymorphicComponent,
  TabsProps,
  Tabs,
  clsx,
} from "@mantine/core";

const _Tabs = forwardRef<HTMLDivElement, TabsProps>((props, ref) => {
  const { classNames, children, styles, ...remainder } = props;
  const {
    root,
    tabLabel = "px-4",
    tabsList = "gap-2",
    tab = "mb-0",
    ...rest
  } = { ...classNames };

  return (
    <Tabs
      ref={ref}
      classNames={{
        root: clsx("overflow-auto", root),
        tabLabel: clsx("h-full w-full flex items-center", tabLabel),
        tabsList: clsx("flex bg-transparent !border-b-0", tabsList),
        tab: clsx("ecn-button border-solid", tab),
        ...rest,
      }}
      styles={{
        tab: {
          "&[data-active]": {
            border: "1px solid #EF4444",
            background: "#F9E3E3",
            color: "#DD5A56",

            "&:hover": {
              borderColor: "#9A9A9A",
              color: "#54565B",
            },
          },
        },
        ...styles,
      }}
      {...remainder}
    >
      {children}
    </Tabs>
  );
});

_Tabs.displayName = "Tablet";
export const Tablet = createPolymorphicComponent<"div", TabsProps>(_Tabs);
