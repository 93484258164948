import { SelectItemProps } from "@mantine/core";
import { AccessorArrayType, ColumnArrayType, QueryItem } from "@/components";

import CryptoJS from "crypto-js";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(LocalizedFormat);

interface InputFormatter {
  locales?: string;
  options?: Intl.NumberFormatOptions;
  unit?: string;
  regex?: {
    integral?: RegExp;
    decimal?: RegExp;
    fractional?: RegExp;
  };
}

export const _inputFormatter = ({
  regex,
  locales = "en-KE",
  options = {
    maximumFractionDigits: 3,
  },
}: InputFormatter = {}) => ({
  formatter: (value = "") => {
    const exp = {
      dots: /(?<=\..*)\./g,
      symbolic: /[^\d\.$]+/g,
      fractional: /\d*/,
      decimal: /[.][0]*/,
      integral: /\d+/,
      ...regex,
    };

    const capture = new RegExp(
      `(?<integral>${exp.integral.source})?` +
        `(?<decimal>${exp.decimal.source})?` +
        `(?<fractional>${exp.fractional.source})?`
    );

    const formatting = new Intl.NumberFormat(locales, options)
      .format(Number(value?.replace(exp.symbolic, "").replace(exp.dots, "")))
      .trim();

    const {
      integral = "",
      fractional = "",
      decimal = "",
    } = {
      ...capture.exec(value)?.groups,
    };
    return value.endsWith(decimal) ? formatting + decimal : formatting;
  },
  parser: (value = "") => value.replace(/(,*)/g, ""),
  removeTrailingZeros: true,
});

export function _numberFormatter(
  value: number | string = 0,
  unit = "KES",
  decimal_places = 3
): string {
  try {
    return value
      ? new Intl.NumberFormat("en-KE", {
          style: unit ? "currency" : undefined,
          currency: unit ? unit : undefined,
          minimumFractionDigits: 0,
          maximumFractionDigits: decimal_places,
        })
          .format(Number(value))
          .trim()
      : Number.isNaN(value)
      ? ""
      : "0";
  } catch (e) {
    return new Intl.NumberFormat("en-KE").format(Number(value));
  }
}

export function _dateFormatter(
  date: Date | string | null = "",
  format: string | undefined = undefined
) {
  return dayjs(date, format).isValid()
    ? dayjs(date, format).format("LLL")
    : null;
}

export function toURLSearchParams(params) {
  return new URLSearchParams(params).toString();
}

export function getHiddenColumns<T extends object>(
  columns: ColumnArrayType<T>,
  visibleColumns: AccessorArrayType<T>
) {
  const stringColumns = columns
    .filter(({ accessor }) => typeof accessor === "string")
    .map(({ accessor }) => accessor) as AccessorArrayType<T>;
  return stringColumns.filter((accessor) => !visibleColumns.includes(accessor));
}

export function getUniqueValues(data: SelectItemProps[] = []) {
  const keyToValue = data?.map((item) => [item.value, item]);
  return Object.values<{ label: string; value: string }>(
    Object.fromEntries(keyToValue)
  );
}

export const createKey = (...text) =>
  CryptoJS.SHA256(JSON.stringify(text)).toString(CryptoJS.enc.Base64);

export function getUniqueFilterValues(arr: QueryItem[]) {
  const filterCheck: string[] = [];
  return arr.filter((e) => {
    if (filterCheck.includes(e.key)) return false;
    return filterCheck.push(e.key), true;
  });
}

// export function decrypt(value) {
//   const bytes = CryptoJS.AES.decrypt(value, process.env.NEXT_PUBLIC_KEY);
//   return bytes.toString(CryptoJS.enc.Utf8);
// }

// export function encrypt(value) {
//   return CryptoJS.AES.encrypt(value, process.env.NEXT_PUBLIC_KEY).toString();
// }

export function decrypt(value) {
  const data = Buffer.from(value, "base64").toString();
  return data;
}

export function encrypt(value) {
  return Buffer.from(value).toString("base64");
}
