import { randomId } from "@mantine/hooks";
import { useQuery } from "react-query";
import { useMemo } from "react";

import { SecurityListDataType, SecurityListType } from "@/sample/queries";
import { authGetter } from "@/utils/utils";
import { queryVars } from ".";

type SecurityField = keyof SecurityListType["data"][number];

export function useGetSecurityList(value: SecurityField = "code") {
  const { query, keys } = queryVars;
  const { data: securityListData, isLoading: securityListLoading } =
    useQuery<SecurityListType>(keys.securityList, () =>
      authGetter(query.securityList)
    );

  const securityOptions = useMemo(() => {
    return securityListData?.data
      ?.sort((a, b) => a?.name?.localeCompare(b?.name))
      ?.map((security) => ({
        label: [security.name, "(" + security.code + ")"].join(" "),
        value: `${security[value]}`,
        key: randomId(),
      }));
  }, [securityListLoading]);

  const securities: {
    [code: SecurityListDataType["code"]]: string;
  } = useMemo(() => {
    return (
      securityListData?.data?.reduce(
        (acc, { name, code }) => ((acc[code] = name), acc),
        {}
      ) ?? {}
    );
  }, [securityListLoading]);

  const securityListByBoard: {
    [code: SecurityListDataType["code"]]: Array<SecurityListDataType>;
  } = useMemo(() => {
    return (
      securityListData?.data
        ?.sort((a, b) => a.code.localeCompare(b.code))
        ?.reduce((acc, data) => {
          const {
            board: { name },
          } = data;
          acc["All"] = (acc["All"] ?? []).concat([data]);

          if (data?.security_type === "Dawa") {
            acc["Deliverable"] = (acc["Deliverable"] ?? []).concat([data]);
            return acc;
          }

          acc[name] = (acc[name] ?? []).concat([data]);
          return acc;
        }, {}) ?? {}
    );
  }, [securityListLoading]);

  const securityList: {
    [code: SecurityListDataType["code"]]: Record<string, any>;
  } = useMemo(() => {
    return (
      securityListData?.data?.reduce(
        (acc, { code, ...remainder }) => ((acc[code] = remainder), acc),
        {}
      ) ?? {}
    );
  }, [securityListLoading]);

  return {
    securityListData,
    securityListLoading,
    securityListByBoard,
    securityOptions,
    securityList,
    securities,
  };
}
