import { ReactNode, useEffect, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { debounce, DebouncedFunc } from "lodash";

import { Group, Loader } from "@mantine/core";
import { randomId } from "@mantine/hooks";

import { AccountListType } from "@/sample/queries";
import { authGetter } from "@/utils/utils";

interface SelectProps {
  label: string;
  key: string;
  value: string;
}

type UseAccountSearch = [
  {
    data: SelectProps[];
    searchable: true;
    nothingFound: ReactNode | "No results found";
    onSearchChange: DebouncedFunc<(q: string) => void>;
  },
  UseQueryResult<AccountListType>,
  string
];

export function useAccountSearch(currency?: string): UseAccountSearch {
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState<Array<SelectProps>>([]);

  const options = {
    enabled: !!searchValue,
  };

  const query = useQuery<AccountListType, "string">(
    ["search-accounts", currency, searchValue].filter(Boolean),
    () => authGetter(`search-accounts?q=${searchValue}&currency_code=${currency}`),
    options
  );

  useEffect(() => {
    if (!searchValue || !query?.data) return;
    setData(
      query?.data?.data
        ?.sort((a, b) => {
          return a?.name
            ?.toLocaleLowerCase()
            ?.localeCompare(b?.name?.toLocaleLowerCase());
        })
        ?.map(({ name, code }) => ({
          label: `${name} (${code})`,
          value: code,
          key: randomId(),
        })) ?? []
    );
  }, [query.isFetched, searchValue]);

  return [
    {
      data,
      searchable: true,
      nothingFound: query.isLoading ? (
        <Group px="sm" spacing="sm">
          <span>Loading...</span>
          <Loader size="sm" />
        </Group>
      ) : (
        "No results found"
      ),
      onSearchChange: debounce(
        (q: string) => setSearchValue(q.replace(/\s+\(?.*$/g, "")),
        650
      ),
    },
    query,
    searchValue,
  ];
}
