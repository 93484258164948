import { createFormContext } from "@mantine/form";
import { FilterFormType } from "./multiColumnFilter";

const [FormProvider, useFormContext, useForm] =
  createFormContext<FilterFormType>();

export {
  FormProvider as FilterFormProvider,
  useFormContext as useFilterFormContext,
  useForm as useFilterForm,
};

export function FilterProvider({ children }) {
  const filterForm = useForm({
    initialValues: {
      search: "",
      pageSize: "10",
      queries: [],
    },
  });
  return <FormProvider form={filterForm}>{children}</FormProvider>;
}
