import { Group, Select, Text } from "@mantine/core";
import { Column } from "react-table";
import {
  Fragment,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import {
  MultiColumnFilter,
  QueryItem,
  useFilterFormContext,
} from "@/components";
import { getUniqueFilterValues } from "@/utils/lib";

export type ColumnArrayType<D extends object> = Array<Column<D>>;
export type AccessorArrayType<D extends object> = Array<
  Extract<Column<D>["accessor"], string>
>;

export interface FilterBarProps<D extends object> {
  columns: ColumnArrayType<D>;
  setParams?: Dispatch<SetStateAction<Record<string, any>>>;
  dataSample?: Array<D>;
  omit?: AccessorArrayType<D>;
  children?: React.ReactNode;
  hideSearchBar?: boolean;
  hideMultiColumnFilter?: boolean;
  hideQueryFilter?: boolean;
  hidePageSize?: boolean;
  queries?: QueryItem[];
  dateFormat?: string;
}

function getInnerText(el) {
  if (el?.constructor.name === "Object") {
    if ("props" in el) {
      return getInnerText(el?.props.children);
    }
  }
  return el?.toString();
}

type DataOptions<D> = Record<Extract<keyof D, string>, Array<any>>;

export function FilterBar<D extends object>({
  setParams,
  columns = [],
  dataSample = [],
  children = null,
  hideSearchBar = false,
  hideMultiColumnFilter = false,
  hideQueryFilter = false,
  hidePageSize = false,
  dateFormat = "DD/MM/YYYY",
  omit = [],
  queries = [],
}: FilterBarProps<D>) {
  const dataOptions = useMemo(() => {
    return dataSample.reduce<DataOptions<D>>((acc, next) => {
      for (let i in next) {
        const element = acc[i] ?? [];
        const nextValue = getInnerText(next[i]);

        // CHECK IF REPEATED OR OMITTED
        const shouldOmit = (omit as string[]).includes(i);
        const isRepeated = element.includes(nextValue);
        if (!nextValue || shouldOmit || isRepeated) continue;

        // CONCATENATE WITH PREVIOUS VALUE
        acc[i] = element.concat([nextValue]);
      }
      return acc;
    }, {} as Record<Extract<keyof D, string>, any[]>);
  }, [dataSample]);

  const filterForm = useFilterFormContext();

  useEffect(() => {
    queries.forEach((query) => {
      const callback = (each) => each.key === query.key;
      const exists = filterForm.values.queries.find(callback);
      if (!exists) filterForm.insertListItem("queries", query);
    });
  }, []);

  useEffect(() => {
    const { search, pageSize, queries } = filterForm.values;
    const uniqueQueries = getUniqueFilterValues(queries);

    setParams?.({
      ...(search && { q: search }),
      ...(queries.length && { queries: uniqueQueries }),
      page_size: pageSize,
    });
  }, [filterForm.values]);

  const queryFilter = hideQueryFilter ? null : (
    <Group spacing="xs">
      <Text>Filter:</Text>
      <MultiColumnFilter<D>
        dateFormat={dateFormat}
        hideMultiColumnFilter={hideMultiColumnFilter}
        hideSearchBar={hideSearchBar}
        dataOptions={dataOptions}
        columns={columns}
        omit={omit as string[]}
        {...filterForm.getInputProps("search")}
      />
    </Group>
  );

  const [data, setData] = useState<Array<string>>(["10", "20", "50", "100"]);

  const size = hidePageSize ? null : (
    <Group spacing="xs">
      <span>Show</span>
      <Select
        classNames={{
          input: "w-24",
          dropdown: "w-max",
        }}
        creatable
        searchable
        getCreateLabel={(query) => query}
        data={data}
        onCreate={(query) => {
          setData((current) => [...current, query]);
          return query;
        }}
        defaultValue="10"
        {...filterForm.getInputProps("pageSize")}
      />
      <span>entries</span>
    </Group>
  );

  const contents = [queryFilter, children, size];
  return (
    <Group position="apart" className="text-primary-90">
      {contents.map((item, idx) => (
        <Fragment key={idx}>{item}</Fragment>
      ))}
    </Group>
  );
}
