import { showNotification } from "@mantine/notifications";
import { useHandleError } from ".";

export function useHandleSuccess(successCallback = () => {}) {
  const handleError = useHandleError();

  return function (response) {
    if (response?.responseCode === "100") {
      showNotification({
        autoClose: 5_000,
        title: "Operation successful",
        message: response?.message,
        color: "green",
      });
      successCallback();
    } else handleError(response)
  };
}
