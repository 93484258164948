import { useGetCurrencies } from "@/hooks/utils";
import { Group, GroupProps, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Dispatch, SetStateAction, useEffect } from "react";

interface ICurrencyFilter extends GroupProps {
  showLabel?: boolean;
  setParams: Dispatch<SetStateAction<Record<string, string>>>;
  alt?: "currency" | "currency_code" | "currency__code";
  currencyOptions?: {
    label: string;
    value: string;
  }[];
}

export function CurrencyFilter({
  children,
  setParams,
  currencyOptions,
  alt = "currency_code",
  showLabel = false,
  ...props
}: ICurrencyFilter) {
  const { currencyOptions: dbCurrencyOptions } = useGetCurrencies("code");

  const form = useForm({
    initialValues: {
      [alt]: "",
    },
  });

  useEffect(() => {
    setParams((prevParams) => {
      if (form.values[alt]) {
        return {
          ...prevParams,
          [alt]: form.values[alt] ?? "",
        };
      } else {
        delete prevParams[alt];
        return prevParams;
      }
    });
  }, [form.values[alt]]);

  return (
    <Group position="apart" {...props}>
      {children}
      <Select
        searchable
        label={showLabel ? "Currency" : undefined}
        lh={showLabel ? 2 : undefined}
        data={currencyOptions ?? dbCurrencyOptions ?? []}
        placeholder="Select currency"
        {...form.getInputProps(alt)}
      />
    </Group>
  );
}
