import {
  useTable,
  useSortBy,
  useExpanded,
  usePagination,
  useRowSelect,
  TableOptions,
  Row,
  useFilters,
  useGlobalFilter,
  FilterType,
  FilterTypes,
  FilterValue,
  ColumnInstance,
} from "react-table";
import { ReactNode, useMemo } from "react";
import { matchSorter } from "match-sorter";
import { CSSObject, Table as MTable, TableProps, useCss } from "@mantine/core";

import {
  forwardRef,
  useRef,
  useEffect,
  createElement,
  Fragment,
  useState,
} from "react";

export type SideEffects = {
  canSelect?: boolean;
  canSort?: boolean;
  canPage?: boolean;
  canEdit?: boolean;
  canExpand?: boolean;
  canFilter?: boolean;
};

export type SubComponentRenderType<D extends object> = ({
  row,
  visibleColumns,
}: {
  row: Row<D>;
  visibleColumns: ColumnInstance<D>[];
}) => ReactNode;

export interface UseMakeTable<D extends object = {}> extends TableOptions<D> {
  renderRowSubComponent?: SubComponentRenderType<D>;
  sideEffect?: SideEffects;
}

export interface ITHead extends React.ComponentPropsWithoutRef<"th"> {
  header?: "Header" | string;
  classNames?: Partial<{
    th: string;
    tr: string;
    thead: string;
  }>;
  styles?: Partial<{
    th: CSSObject;
    tr: CSSObject;
    thead: CSSObject;
  }>;
}

export interface ITBody extends React.ComponentPropsWithoutRef<"td"> {
  classNames?: Partial<{
    td: string;
    tr: string;
    tbody: string;
    input: string;
  }>;
  styles?: Partial<{
    td: CSSObject;
    tr: CSSObject;
    tbody: CSSObject;
    input: CSSObject;
  }>;
}

export interface ITFoot extends React.ComponentPropsWithoutRef<"td"> {
  classNames?: Partial<{
    td: string;
    tr: string;
    tfoot: string;
  }>;
  styles?: Partial<{
    td: CSSObject;
    tr: CSSObject;
    tfoot: CSSObject;
  }>;
}

function ArrowUp(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path fill="currentColor" d="m7 14l5-5l5 5H7z"></path>
    </svg>
  );
}

function ArrowDown(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path fill="currentColor" d="m7 10l5 5l5-5H7z"></path>
    </svg>
  );
}

function IndeterminateCheckbox({ indeterminate, ...rest }, ref) {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input title="checkbox" type="checkbox" ref={resolvedRef} {...rest} />;
}

const Checkbox = forwardRef(IndeterminateCheckbox);

const EditableCell = ({
  value: initialValue,
  row: { index: rowIndex },
  column: { id: columnId },
  editProps,
}) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => setValue(e.target.value);
  const onBlur = () => {
    editProps.setSkipPageReset(true);
    editProps.setData((old) => {
      return old.map((row, index) => {
        return index === rowIndex
          ? {
              ...old[rowIndex],
              [columnId]: value,
            }
          : row;
      });
    });
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      title="canEdit"
      className="bg-transparent"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} entries...`}
    />
  );
}

function textFilterFn<D extends object>(rows, id, filterValue) {
  return matchSorter<Row<D>>(rows, filterValue, {
    keys: [(row) => row.values[id]],
  });
}

textFilterFn.autoRemove = (val: FilterValue) => !val;

export function useMakeTable<D extends object = {}>({
  renderRowSubComponent,
  sideEffect: {
    canSelect = false,
    canSort = false,
    canPage = false,
    canEdit = false,
    canExpand = false,
    canFilter = true,
  } = {},
  ...options
}: UseMakeTable<D>) {
  const [skipPageReset, setSkipPageReset] = useState(false);

  const filterTypes: FilterTypes<D> = {
    text: textFilterFn as FilterType<D>,
  };

  if (canEdit) {
    options["defaultColumn"] = {
      ...EditableCell,
    };
    options["autoResetPage"] = !skipPageReset;
    options["editProps"] = { setSkipPageReset };
  }

  if (canFilter) {
    options["defaultColumn"] = {
      ...options["defaultColumn"],
      Filter: DefaultColumnFilter,
    };
    options["filterTypes"] = filterTypes;
  }

  const table = useTable(
    {
      ...options,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        const selection = {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <Checkbox
              {...getToggleAllPageRowsSelectedProps()}
              className="place-self-bottom"
            />
          ),
          Cell: ({ row }: { row: Row<D> }) => (
            <Checkbox {...row.getToggleRowSelectedProps()} />
          ),
        };
        const selectableRows = canSelect ? [selection] : [];
        return [...selectableRows, ...columns];
      });
    }
  );

  const {
    headerGroups,
    footerGroups,
    getTableProps,
    getTableBodyProps,
    visibleColumns,
    rows,
    prepareRow,
    page,
    pageCount,
    selectedFlatRows,
    state,
    gotoPage,
  } = table;

  function THead({
    header = "Header",
    styles,
    className,
    classNames,
    ...props
  }: Partial<ITHead>) {
    const { css, cx } = useCss();
    return (
      <thead className={cx(css(styles?.thead), classNames?.thead)}>
        {headerGroups.map((headerGroup) => {
          const { key: trKey, ...HeaderGroup } =
            headerGroup.getHeaderGroupProps();

          return (
            <tr
              key={trKey}
              {...HeaderGroup}
              className={cx(css(styles?.tr), classNames?.tr, className)}
            >
              {headerGroup.headers.map((column) => {
                const { key: thKey, ...ColumnProps } = column.getHeaderProps(
                  canSort ? column.getSortByToggleProps() : {}
                );

                const SortedElement = createElement(
                  column.isSortedDesc ? ArrowDown : ArrowUp,
                  {
                    style: {
                      display: "inline",
                      marginLeft: 2,
                      fontSize: "1.35rem",
                    },
                  }
                );

                return (
                  <th
                    key={thKey}
                    {...ColumnProps}
                    className={cx(css(styles?.th), classNames?.th)}
                    colSpan={column["colSpan"] ?? 1}
                    rowSpan={column["rowSpan"] ?? 1}
                    {...props}
                  >
                    <Fragment>
                      <span>{column.render(header)}</span>
                      {canSort && column.isSorted && SortedElement}
                    </Fragment>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
    );
  }

  function TBody({ styles, className, classNames, ...props }: Partial<ITBody>) {
    const pageOrRow = useMemo(() => (canPage ? page : rows), [options.data]);
    const { css, cx } = useCss();

    return (
      <tbody
        {...getTableBodyProps()}
        className={cx(css(styles?.tbody), classNames?.tbody)}
      >
        {pageOrRow.map((row) => {
          prepareRow(row);
          const rowProps = row.getRowProps();

          return (
            <Fragment key={rowProps.key}>
              <tr
                {...rowProps}
                {...(canExpand && row.getToggleRowExpandedProps())}
                className={cx(css(styles?.tr), classNames?.tr, className)}
              >
                {row.cells.map((cell) => {
                  const { key: tdKey, ...CellProps } = cell.getCellProps();
                  return (
                    <td
                      key={tdKey}
                      {...CellProps}
                      colSpan={row["colSpan"] ?? 1}
                      className={cx(css(styles?.td), classNames?.td)}
                      {...props}
                    >
                      {cell.render("Cell", {
                        ...(canEdit && {
                          className: cx(css(styles?.input), classNames?.input),
                        }),
                      })}
                    </td>
                  );
                })}
              </tr>
              {canExpand && row.isExpanded && renderRowSubComponent && (
                <>{renderRowSubComponent({ row, visibleColumns })}</>
              )}
            </Fragment>
          );
        })}
      </tbody>
    );
  }

  function TFoot({ styles, className, classNames, ...props }: Partial<ITFoot>) {
    const { css, cx } = useCss();

    return (
      <tfoot className={cx(css(styles?.tfoot), classNames?.tfoot)}>
        {footerGroups.map((group) => {
          const { key: trKey, ...FooterGroup } = group.getFooterGroupProps();
          return (
            <tr
              key={trKey}
              {...FooterGroup}
              className={cx(css(styles?.tr), classNames?.tr, className)}
            >
              {group.headers.map((column) => {
                const { key: tdKey, ...FooterProps } = column.getFooterProps();
                return (
                  <td
                    key={tdKey}
                    {...FooterProps}
                    className={cx(css(styles?.td), classNames?.td)}
                    {...props}
                  >
                    {column.render("Footer")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tfoot>
    );
  }

  function Table({ children, ...props }: TableProps) {
    return (
      <MTable {...getTableProps()} {...props}>
        {children}
      </MTable>
    );
  }

  Table.Head = THead;
  Table.Body = TBody;
  Table.Foot = TFoot;

  const { pageSize, pageIndex } = state;

  return {
    ...table,
    selectedRows: selectedFlatRows.map((d) => d?.original),
    rowEndIndex: pageIndex * pageSize + page.length,
    rowStartIndex: pageSize * pageIndex + 1,
    lastPage: () => gotoPage(pageCount - 1),
    firstPage: () => gotoPage(0),
    rowLength: rows.length,
    Table,
  };
}