import clsx from "clsx";

interface INotificationIcon {
  children?: number;
  sup?: boolean;
}

export function NotificationIcon({ children, sup = false }: INotificationIcon) {
  return children ? (
    <small
      className={clsx(
        "font-bold text-[.625rem] text-white bg-accent-90 rounded-full pt-[.1rem] pb-[.2rem]",
        sup ? "ml-3.5 mb-4 mr-0.5 px-[.3rem] leading-[.65rem]" : "px-2.5"
      )}
    >
      {children}
    </small>
  ) : null;
}
