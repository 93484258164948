import { authGetter } from "@/utils/utils";
import { useEffect, useMemo } from "react";
import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseQueryResult,
} from "react-query";
import { QueryResult } from "./useIncrementalQuery";

export function useCustomQuery<
  D extends QueryResult<T>,
  T extends "array" | "object" = "array"
>(keys: QueryKey, url: string, options?: UseInfiniteQueryOptions<D>) {
  function urlQuery({ pageParam = 1 }) {
    const searchParams = new URLSearchParams({
      page: `${pageParam}`,
    });

    const zip = url?.includes("?") ? "&" : "?";
    return authGetter(url + zip + searchParams);
  }

  const { data: fetchedData, ...props } = useInfiniteQuery<D>(keys, urlQuery, {
    ...options,
    keepPreviousData: true,
    optimisticResults: false,
    refetchOnWindowFocus: false,
    getNextPageParam(lastPage, pages) {
      return lastPage?.next ? pages.length + 1 : null;
    },
  });

  const data: D["data"] =
    fetchedData?.pages?.reduce((acc, curr) => {
      const { data, ...rest } = { ...curr };
      return Object.assign(acc, rest, {
        data: Array.isArray(data) ? (acc["data"] ?? []).concat(data) : data,
      });
    }, {} as D["data"]) ?? fetchedData?.pages?.at(0)?.data;

  useEffect(() => {
    if (!props.isFetching && props.hasNextPage) props.fetchNextPage();
  }, [props.isFetching]);

  const queryResult = useMemo(() => {
    return {
      ...props,
      isLoading: props.isFetching,
      data,
    } as UseQueryResult<D>;
  }, [data, props.isFetching]);

  return queryResult;
}
