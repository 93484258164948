import { TenantListDataType, TenantListType } from "@/sample/queries";
import { authGetter } from "@/utils/utils";
import { useMemo } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import { queryVars } from ".";

interface UseGetTenantList {
  value?: keyof TenantListType["data"][number];
  options?: UseQueryOptions<any>;
}

export function useGetTenantList({
  value = "id",
  options = {},
}: UseGetTenantList = {}) {
  const { query, keys } = queryVars;
  const { data: tenantListData, isLoading: tenantListLoading } =
    useQuery<TenantListType>(
      keys.tenantList,
      () => authGetter(query.tenantList),
      options
    );

  const tenantOptions = useMemo(() => {
    return (
      tenantListData?.data
        ?.filter(({ is_active }) => is_active)
        ?.sort((a, b) => a.company_name.localeCompare(b.company_name))
        ?.map((tenant) => ({
          label: tenant["company_name"],
          value: tenant[value],
        })) ?? []
    );
  }, [tenantListLoading]);

  const tenantList: {
    [value: string]: TenantListDataType;
  } = useMemo(() => {
    return (
      tenantListData?.data?.reduce(
        (acc, entry) => ((acc[entry[value]] = entry), acc),
        {}
      ) ?? {}
    );
  }, [tenantListLoading]);

  return {
    tenantListData,
    tenantListLoading,
    tenantOptions,
    tenantList,
  };
}
