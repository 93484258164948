import { showNotification } from "@mantine/notifications";

export function clearErrorMessage(errors, message = "") {
  const value = (sentence) =>
    sentence
      .split(" ")
      .filter((e) => /\w/.test(e))
      .join(" ");

  const extract = (node) => {
    return node
      .map((e, i) => {
        const prefix = i + 1 === node.length ? " └─ " : " ├─ ";
        return prefix + value(e);
      })
      .join("\n");
  };

  try {
    if (typeof errors === "string") return errors;
    else if (Array.isArray(errors)) {
      message += `\nerrors\n${extract(errors.flat(Infinity))}`;
    } else if (errors && errors?.constructor?.name === "Object") {
      let breakLine = "";
      for (const field in errors) {
        message += `${breakLine}${field}\n${extract(errors[field])}`;
        breakLine = "\n";
      }
    }
  } catch (e) {
    return JSON.stringify(errors);
  }

  return message;
}

interface UseHandleError {
  onError?(err?: string): void;
  error?: {
    title?: string;
    message?: string;
  };
}

export function useHandleError({
  error = { title: "Operation failed" },
  onError,
}: UseHandleError = {}) {
  return function (err) {
    const { errors = null, data = null, message = null } = { ...err };

    showNotification({
      title: message ?? error?.title,
      message: (
        <pre className="max-h-full overflow-auto whitespace-pre-line">
          {clearErrorMessage(errors ?? data ?? error.message)}
        </pre>
      ),
      color: "accent.8",
    });
    onError?.();
  };
}
