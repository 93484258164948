import {
  Button,
  ButtonProps,
  createPolymorphicComponent,
  Divider,
  HoverCard,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { MessageQuestion } from "iconsax-react";
import { Draggable } from "drag-react";
import { forwardRef, SVGProps } from "react";

function RedDonut(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="72"
      height="36"
      viewBox="0 0 72 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.15"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.4558 25.4558C68.2072 18.7045 72 9.54779 72 0H36L0 3.8147e-06C0 9.5478 3.79285 18.7045 10.5442 25.4558C17.2955 32.2072 26.4522 36 36 36C45.5478 36 54.7045 32.2072 61.4558 25.4558ZM56.9711 8.6865C58.1118 5.93256 58.6989 2.9809 58.6989 4.57764e-05H36.0001H13.3013C13.3013 2.9809 13.8885 5.93255 15.0292 8.6865C16.1699 11.4404 17.8419 13.9427 19.9497 16.0505C22.0574 18.1583 24.5597 19.8303 27.3137 20.971C30.0676 22.1117 33.0193 22.6988 36.0001 22.6988C38.981 22.6988 41.9326 22.1117 44.6866 20.971C47.4405 19.8303 49.9428 18.1583 52.0506 16.0505C54.1584 13.9427 55.8304 11.4404 56.9711 8.6865Z"
        fill="#F178B6"
      />
    </svg>
  );
}

function YellowDonut(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="168"
      height="158"
      viewBox="0 0 168 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.15"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.5 158C193.259 158 249 102.259 249 33.5C249 -35.2595 193.259 -91 124.5 -91C55.7405 -91 0 -35.2595 0 33.5C0 102.259 55.7405 158 124.5 158ZM124.5 112C167.854 112 203 76.8544 203 33.5C203 -9.85435 167.854 -45 124.5 -45C81.1457 -45 46 -9.85435 46 33.5C46 76.8544 81.1457 112 124.5 112Z"
        fill="#E08100"
      />
    </svg>
  );
}

function GreenDonut(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="93"
      height="47"
      viewBox="0 0 93 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.15"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.3805 13.6195C88.1009 22.34 93 34.1674 93 46.5H75.8194C75.8194 42.6498 75.061 38.8374 73.5876 35.2803C72.1142 31.7231 69.9545 28.491 67.232 25.7684C64.5094 23.0459 61.2773 20.8863 57.7201 19.4128C54.1629 17.9394 50.3504 17.181 46.5001 17.181C42.6499 17.181 38.8373 17.9394 35.2801 19.4128C31.7229 20.8863 28.4908 23.0459 25.7683 25.7684C23.0457 28.491 20.8861 31.7231 19.4126 35.2803C17.9392 38.8374 17.1809 42.6498 17.1808 46.5L0 46.5C0 34.1674 4.8991 22.34 13.6195 13.6195C22.34 4.89909 34.1674 0 46.5 0C58.8326 0 70.66 4.89909 79.3805 13.6195Z"
        fill="#86C105"
      />
    </svg>
  );
}

const _FloatingActionButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...others }, ref) => (
    <Draggable
      style={{
        position: "fixed",
        height: "min-content",
        inset: "auto 20px 20px auto",
        width: "min-content",
      }}
    >
      <HoverCard width={350} shadow="md" position="top-end">
        <HoverCard.Target>
          <Button
            p="sm"
            ref={ref}
            {...others}
            className="h-auto rounded-full bg-semantic-20"
          >
            <MessageQuestion variant="Outline" size={30} />
          </Button>
        </HoverCard.Target>
        <HoverCard.Dropdown p={0}>
          <Stack
            py="xl"
            px="lg"
            className="relative grid gap-3 overflow-hidden rounded-md w-[350px] bg-generic-background shadow-sm text-primary-90"
          >
            <RedDonut className="absolute -top-0 left-8" />
            <Title order={4} className="pl-4">
              Journal Entry table
            </Title>
            <YellowDonut className="absolute -right-0 -top-0" />
            <div className="flex gap-4">
              <Divider
                className="self-center flex-shrink-0 w-1 border-0 bg-accent-90 h-[90%]"
                size="xl"
                orientation="vertical"
              />
              <Text weight={400} size="sm">
                Journal Entry table is revealed when user hovers over target
                element, it will be hidden once mouse is not over both target
                and dropdown elements
              </Text>
            </div>
            <GreenDonut className="absolute -bottom-0 left-12" />
          </Stack>
        </HoverCard.Dropdown>
      </HoverCard>
    </Draggable>
  )
);

_FloatingActionButton.displayName = "FloatingActionButton";
export const FloatingActionButton = createPolymorphicComponent<
  "button",
  ButtonProps
>(_FloatingActionButton);
