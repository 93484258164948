import { UseQueryResult } from "react-query";
import { queryVars, QueryVarsType, QueryResult, useCustomQuery } from ".";

export function useGetClient<
  D extends QueryResult<T>,
  T extends "array" | "object" = "array"
>(name: keyof QueryVarsType["query"], user, ...deps): UseQueryResult<D> {
  const { keys: queryKeys, query } = queryVars;
  
  const keys = queryKeys[name] as (cid: string) => string[];
  const url = query[name] as (cid: string) => string;

  return useCustomQuery<D, T>(keys(user).concat(deps), url(user), {
    enabled: !!user,
  });
}
