import { TableProps } from "@mantine/core";
import { Column } from "react-table";
import { useEffect, useMemo } from "react";
import { HookArgs, QueryResult, useColumns } from "@/hooks/utils";
import { useQuery, UseQueryOptions } from "react-query";
import {
  ContextBar,
  TableLoading,
  TablePagination,
  useMakeTable,
  ITBody,
  ITHead,
  SideEffects,
  SubComponentRenderType,
} from "@/components";
import { authGetter } from "@/utils/utils";

type UseStandaryQueryTable<D extends QueryResult, T extends object> = {
  columns: Array<Column<T>>;
  hiddenColumns: Array<string>;
  sideEffect?: SideEffects;
  dependencies?: Array<any>;
  dataFilterer: (data: D["data"]) => Array<T>;
  renderRowSubComponent?: SubComponentRenderType<T>;
  hookArgs: HookArgs;
  options?: UseQueryOptions<D>;
  name: string;
};

export function useStandardQueryTable<
  D extends QueryResult<any>,
  T extends object
>({
  columns,
  hiddenColumns = [],
  sideEffect,
  dependencies = [],
  renderRowSubComponent = () => null,
  dataFilterer,
  hookArgs,
  options,
  name,
}: UseStandaryQueryTable<D, T>) {
  const { data: fetchedData, ...props } = useQuery<D>(
    hookArgs.keys,
    () => authGetter(hookArgs.url),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );

  const data = useMemo(
    () => dataFilterer(fetchedData?.data ?? []) ?? [],
    [props.isLoading, ...dependencies]
  );

  const {
    Table,
    visibleColumns,
    setHiddenColumns,
    setPageSize,
    state: { pageIndex },
    ...tableProps
  } = useMakeTable({
    data,
    columns,
    renderRowSubComponent,
    initialState: {
      hiddenColumns,
      pageSize: +(hookArgs?.params?.page_size || 10),
    },
    sideEffect: {
      canPage: true,
      ...sideEffect,
    },
  });

  useEffect(() => {
    setPageSize(+(hookArgs?.params?.page_size || 10));
  }, [hookArgs?.params?.page_size]);

  interface CustomTableProps {
    headProps: ITHead;
    bodyProps: ITBody;
    tableProps: TableProps;
  }

  function CustomTable({
    headProps = {},
    bodyProps = {},
    tableProps = {},
  }: Partial<CustomTableProps>) {
    return (
      <div className="flex-1 h-full overflow-auto">
        <Table
          highlightOnHover
          verticalSpacing="md"
          fontSize="sm"
          className="h-full overflow-auto text-primary-90 whitespace-nowrap"
          {...tableProps}
        >
          <Table.Head
            classNames={{
              thead: "bg-generic-foreground sticky top-0 z-10",
            }}
            {...headProps}
          />
          <TableLoading
            isError={props.isError}
            visibleColumns={visibleColumns}
            isLoading={props.isLoading}
            data={data}
          >
            <Table.Body
              classNames={{
                tbody: "overflow-auto align-baseline",
              }}
              {...bodyProps}
            />
          </TableLoading>
        </Table>
      </div>
    );
  }

  function CustomPagination() {
    return props.isError || props.isLoading || !data.length ? null : (
      <TablePagination pageIndex={pageIndex} {...tableProps} />
    );
  }

  // COLUMNS FILTERING
  const [values, handlers] = useColumns<T>({
    columns,
    hiddenColumns,
    setHiddenColumns,
    name,
  });

  // TABLE CONTEXT_BAR
  function CustomContextBar() {
    return (
      <ContextBar<D, T>
        filename={name}
        values={values}
        args={hookArgs}
        filterer={dataFilterer}
        handlers={handlers}
        data={data}
      />
    );
  }

  return {
    values,
    handlers,
    setPageSize,
    CustomTable,
    CustomPagination,
    CustomContextBar,
    tableData: data,
    fetchedData,
    ...props,
    ...tableProps,
  };
}
